<template>
  <div class="page">
    <div class="title">Privacy Policy</div>
    <p>1. Introduction</p>
    <p>This privacy policy (“Privacy Policy”) describes how Daily Reading (“we”, “us” or “our”)
      collects, uses and shares information about you; this Privacy Policy applies to your use of
      our (mobile/online) novel platform (the “Platform”), contents, and all its related services to
      Daily Reading (hereinafter referred to collectively as the “Services”)</p>
    <p>Note that our Terms of Service applies to this Privacy Policy. By accessing and using our
      services, you will be deemed to agree to this Privacy Policy. Please take a moment to read
      this Privacy Policy before you start to use our services. If you do not agree to this Privacy
      Policy, you should immediately discontinue access to or the use of our Services.</p>
    <p>2. How we get the information from you?</p>
    <p>We and our Service Providers (defined below) may directly collect your information via the
      Services. For example, we collect information when you perform the following activities: (The
      personal information collected and method of collecting personal information may differ
      slightly depending on the country in which the Service is provided)</p>
    <p>·Creating an account on the Platform;</p>
    <p>·Registering or connecting your existing Daily Reading account with social network services
      (“SNS”) such as Google;</p>
    <p>·Accessing our Services;</p>
    <p>·Participating in any types of events organized by us through the Services;</p>
    <p>·Participating in any types of surveys organized by us through the Services;</p>
    <p>·Making any purchases through the Platform; or</p>
    <p>·Contacting us via methods we provide</p>
    <p>3. What is Personal Information?</p>
    <p>“Personal Information” is any information that identifies, relates to, or could reasonably be
      linked with you.</p>
    <p>4. What personal information do we collect?</p>
    <p>A. Information you provide</p>
    <p>When you create a Daily Reading account, you provide us with Personal Information.</p>
    <p>Daily Reading does not store any credit card or debit card information online. </p>
    <p>B. Information automatically collected </p>
    <p>We may automatically collect certain information about you when you access or use our
      Services (“Usage Information”). Usage Information may include operating system (OS), device
      model, country information, browser information, IP addresses, and other information about
      your use of the Services. The methods that may be used on the Services to collect Usage
      Information include:</p>
    <p>·Log Information</p>
    <p>*Data about your use of the Services, which is stored in log files</p>
    <p>·Tracking Technologies</p>
    <p>*Information about your interaction with the Services or email including your browsing and
      activity behavior collected by cookies, web beacons, in-app tracking methods and other
      technologies now and hereafter developed (“Tracking Technologies”)</p>
    <p>·Cookies</p>
    <p>*We may use cookies to provide customized Services for our users including guest users by
      identifying interests and preferences through analyzing frequency of access and visit time.
      You can disable cookies in your browser settings but some parts of the Services may then not
      function properly. </p>
    <p>·Web Beacons (“Tracking Pixel”)</p>
    <p>*Web Beacons are small graphic images, also known as “internet tags” or “clear gifs”,
      embedded in web pages and email messages. Web Beacons may be used to count the number of
      visitors to the Services, to monitor how users navigate the Services, and to count content
      views.</p>
    <p>·In-app Tracking Methods</p>
    <p>*There are a variety of tracking methods that may be included in our Platform and third-party
      platform. These are not browser-based like cookies and cannot be controlled by browser
      settings. Some use device identifiers, or other identifiers such as “Ad IDs” to associate app
      user activities to a particular app and to track user activities across apps.</p>
    <p>C. Information from other sources</p>
    <p>We may obtain information about you from other sources (as permitted by law) including
      Service Providers and third party services, and combine such information with information we
      have collected directly and/or automatically from you. Following information is transferred to
      us when you register or link your existing account with your account from a third-party
      Service Provider (i.e. Apple) or SNS (i.e. Facebook, Google):</p>
    <p>·The name of the third-party Service Providers or SNS</p>
    <p>·User ID and email address</p>
    <p>D. De-identified personal information</p>
    <p>Personal Information once “de-identified” is NOT subject to this Privacy Policy and we and
      our Supporting Company (defined below) may treat it as non-personal information and use it
      without obligation to you except as prohibited by applicable law.</p>
    <p>5. What we do with the personal information?</p>
    <p>We collect, use, process, share, and/or disclose your information for the following purposes:
    </p>
    <p>·To provide the Services based on contractual relationship with you</p>
    <p>*to provide, operate, and maintain our Platform and Services;</p>
    <p>*to verify, operate, and process user identification for membership service(s) including your
      Daily Reading account;</p>
    <p>*to verify, operate, and process your purchases, transactions, and refunds;</p>
    <p>*to help you or your device register for our Services;</p>
    <p>*to operate our Platform and to analyze your activities;</p>
    <p>*to provide customized Services;</p>
    <p>*to suggest and operate promotional and event activities;</p>
    <p>*to troubleshoot any issues;</p>
    <p>*to monitor and analyze to improve service performance quality;</p>
    <p>*to enhance confidentiality, integrity and availability of security;</p>
    <p>*to develop and improve the operating environment for better Services;</p>
    <p>*to allow you to manage your Wuxiaworld account; and/or</p>
    <p>*to conduct any other activities required to provide our Services</p>
    <p>·To enforce the Terms of Services, Privacy Policy (including relevant Addendums), and other
      agreements with third parties</p>
    <p>*to detect, prevent, investigate, and report violations, unauthorized use of and/or abuse of
      Services, other agreements and/or suspected illegal activities;</p>
    <p>*to resolve any issues and claims in connection with you (including, but not limited to
      collecting/reviewing documents, having legal advice, and proceeding dispute resolution);</p>
    <p>*to store records of dispute settlements;</p>
    <p>*to protect the rights, privacy, safety, and property of us and our Service Providers; and/or
    </p>
    <p>*to enforce other policies and agreements</p>
    <p>·To comply with legal requirements</p>
    <p>*to meet or comply with any applicable rules, laws, regulations, and/or guidelines
      established
      by any legal or regulatory authorities binding on us;</p>
    <p>*to support any criminal investigations in accordance with subpoena, court order or legal
      process for which we, our Service Providers, or you have legal liability; and/or</p>
    <p>*to support requests from government and/or investigative authorities inside and outside of
      the country of your residence</p>
    <p>·To perform customers services</p>
    <p>*to support customer inquiries and complaints given by (or purported to be given by) you or
      on
      your behalf and to carry out your instructions;</p>
    <p>*to notify you of administrative and other important information regarding the Services and
      to
      contact you when necessary; and/or</p>
    <p>*to otherwise render customer support and services to you</p>
    <p>·To promote services, events, and products</p>
    <p>*to send various types of communications for promotional and marketing purposes; and/or</p>
    <p>*to deliver targeted marketing and promotional offers;</p>
    <p>·We may use and process your information for additional purposes under the following
      conditions:</p>
    <p>*additional purposes are disclosed to you before your information is collected for such
      purposes; and/or</p>
    <p>*you consent to collecting/using/processing/disclosing for the additional purposes</p>
    <p>6. When and with whom is the personal information shared and provided?</p>
    <p>In principle, we do not share your personal information with external parties. However, we
      may
      share, and provide your personal information with but not limited to our agents, vendors, and
      consultants (“Supporting Company”) of ‘Wuxiaworld Ltd.’ as well as companies that provide the
      Services on behalf (“Service Providers”) of ‘Daily Reading’ in the following cases:</p>
    <p>·With your consent</p>
    <p>*We may share your information with external parties when we obtain consent to this Privacy
      Policy from you or with your direction</p>
    <p>·With Service Providers (and affiliated companies) for the following processes:</p>
    <p>*Billing and payment processes;</p>
    <p>*Data storage and development; and/or</p>
    <p>*Administrative services and other third-party services required to provide the Services</p>
    <p>· With affiliated companies, group companies, and/or subsidiaries for the following processes
      on behalf of us:</p>
    <p>*Personalizing content and experience; and/or</p>
    <p>*Offering promotions and events for our products and services or third-party’s products and
      services
      ·With law enforcement and appropriate third parties for legal reasons</p>
    <p>*to investigate criminals, fraud, infringement of intellectual property rights, or other
      suspected illegal activities, or as otherwise may be required by applicable laws, or as we
      deem necessary in our sole discretion, in order to protect our legitimate legal and business
      interests.</p>
    <p>·Corporate restructuring</p>
    <p>*We may share Personal Information when we do a business deal, or negotiate a business deal,
      involving the sale or transfer of all or a part of our business or assets. These deals can
      include any merger, financing, acquisition, or bankruptcy transaction or proceeding.</p>
    <p>Except as disclosed in this Privacy Policy, we DO NOT sell, trade, rent any personal
      information we collect online (or mobile) unless we have your explicit permission.</p>
    <p>7. How long we retain the personal information?
      We may process and store your information in accordance with following conditions:</p>
    <p>· while you are using our Platform (while your account is alive, not closed and deleted);</p>
    <p>·as required under law, regulations, contracts or statutory obligations; and/or</p>
    <p>·only for as long as your information is necessary for the purposes described in the Privacy
      Policy.</p>
    <p>8. When and how is the information deleted?
      We will put in place measures such that your personal information in our possession or under
      our control is deleted in an irreversible format and/or de-identified as soon as it is
      reasonable to assume that (1) the purpose for which that personal information was collected is
      no longer being served by the retention of such personal information; and (2) retention is no
      longer necessary for any other legal or business purposes.</p>
    <p>If any personal information is to be retained as required by relevant laws and regulations,
      we
      retain it for the period as required by such laws and regulations before destruction. The
      personal information which is stored and managed by us will not be used for purposes not
      authorized by law or by you.</p>
    <p>However, some information you have shared with others on the Platform may, and will likely,
      remain visible after you close your account such as comments, message board postings, and
      communications you have with other users of the Platform and certain submissions. </p>
    <p>9. Personal information of children
      Daily Reading is very sensitive to the issue of children’s privacy and, therefore, we endeavor
      to protect the privacy of children using the Internet.</p>
    <p>As further described in the Terms, the Platform is intended for users aged eighteen (18) and
      older. We do not intentionally collect, process, use, and share information about users who
      are under the age of thirteen (13) (hereinafter referred to as “Child” or “Children”) based on
      the laws in jurisdiction. However, when it is necessary to collect, process, use, share, and
      disclose information about Child, we will obtain relevant consent from parents or legal
      guardians.</p>
    <p>Parents or legal guardians should supervise their Children when they participate in online
      (mobile) activities. Please notify us if you know of any child using our Platform so we can
      take immediate action to prevent access to our Platform.</p>
    <p>10. Do we transfer your information abroad?
      While you use our Services, your information may be transferred, stored, and processed within
      and outside of your country of residence in accordance with this Privacy Policy.</p>
    <p>We make an effort to implement appropriate technical, physical, and administrative safeguards
      to protect the privacy and security of your information and to use it only consistent with
      your relationship with us and terms described in this Privacy Policy. In addition, we enter
      into service agreements and relevant clauses with our Service Providers whenever feasible and
      appropriate.</p>
    <p>11. What type of security measures have been implemented to protect the personal information?
      We take personal information very seriously and make reasonable efforts to prevent loss,
      theft, leakage, unauthorized alteration, and deletion of personal information through
      implementing appropriate physical, technical, administrative security measures as below (but
      not limited to):</p>
    <p>·Access control (including secure password policy);</p>
    <p>·Pseudonymization and encryption of personal information;</p>
    <p>·Installation of anti-virus software and firewall;</p>
    <p>·Implementation of firewall; and/or</p>
    <p>·Management and monitoring of event logs for accountability</p>
    <p>12. What are your rights regarding your personal information?</p>
    <p>You may exercise your rights regarding personal information as below:</p>
    <p>·Right of access</p>
    <p>*You may ask us to provide copies of your personal information, including how we collect,
      store, use, process, share, and/or disclose information about you. This rights always applies
      unless some exemptions exist.</p>
    <p>·Right to rectification</p>
    <p>*You may contact us if you identify that your information belonging to us is not accurate.
      You
      may ask us to rectify, update, and complete such inaccurate information.</p>
    <p>·Right to erasure (right to be forgotten)</p>
    <p>*You may ask us to delete your information collected, stored, processed, and shared by us
      when
      certain circumstances are met.</p>
    <p>·Right of restriction of processing</p>
    <p>*You may limit the way we collect, process, use, store, share, and disclose your information
      if you are concerned about how we use it.</p>
    <p>·Right to data portability</p>
    <p>*This right only applies to the information you have directly given us. You may ask us to
      transfer such information in a usable format from one organization to an(other)
      organization(s), or give it to you.</p>
    <p>·Right to object</p>
    <p>*You may ask us to stop processing your information when certain circumstances are met.</p>
    <p>13. Changes to this Privacy Policy</p>
    <p>We reserve the right to change, modify, add, or remove portions of this Privacy Policy at any
      time without notice to you in our sole and absolute discretion, and such new Privacy Policy
      will immediately take effect upon its positing on the Plaform. We do not provide any other
      notices of such changes to this Privacy Policy. Please be advised that your use of the
      Platform means that you will be bound by the most current version of the Privacy Policy. We
      encourage you to check back frequently to review the latest version of the Privacy Policy, as
      it is your sole responsibility to be aware of any such changes to this Privacy Policy. If you
      disagree with any changes to this Privacy Policy and do not wish your Personal Information to
      be subject to the revised Privacy Policy, please close your account and/or stop using the
      Services.</p>
    <p>14. How to contact us regarding any inquiries, concerns, and complains?</p>
    <p>If you have any inquiries about your rights, please contact us at
      weichuanbo101905@gmail.combine</p>
    <p>15. Last modified</p>
    <p>June 7, 2024</p>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style  scoped>
.page {
  padding: 30px;
}
.title {
  font-size: 28px;
  text-align: center;
}
p {
  margin: 20px 0 0;
  font-size: 22px;
  text-align: justify;
}
</style>
