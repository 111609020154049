<template>
  <MyHeader />
  <div class="book-list">
    <van-list v-model:loading="loading" :finished="finished" finished-text="You've reached the end"
      @load="onLoad">
      <div class="book-item" v-for="(book, index) in list" :key="index" @click="toDetail(book.id)">
        <div class="book-cover">
          <img v-lazy="book.cover" />
        </div>
        <div class="book-info">
          <div class="top">
            <div class="title">{{ book.title }}</div>
            <div class="author">{{ book.author }}</div>
            <div class="des">{{ book.first_txt }}</div>
          </div>
          <div class="book-status">
            <span>{{ book.completedView }}</span>
            <span>Views: {{ book.views }}</span>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import request from "@/utils/request";
import MyHeader from "@/components/AccountHeader.vue";
import userService from "@/utils/userService";
export default {
  name: "HomePage",
  components: { MyHeader },
  data() {
    return {
      list: [],
      loading: true,
      finished: false,
      page: 1,
    };
  },
  created() {
    userService.updateUserInfo((error, response) => {
      if (error) {
        console.log("==========updateUserInfo error",error)
        return;
      }
      console.log("==========updateUserInfo response",response)
      this.fetchBooks();
    })
  },
  methods: {
    async fetchBooks() {
      try {
        this.loading = true;
        const response = await request({
          url: "/api/index",
          method: "post",
          data: { page: this.page },
        });
        if (response && response.items) {
          this.list = this.list.concat(response.items);
        }
        this.finished = !response.next;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("Error fetching books:", error);
      }
    },
    onLoad() {
      this.page += 1;
      this.fetchBooks();
    },
    toDetail(id) {
      this.$router.push(`/bookDetails/${id}`);
    },
  },
};
</script>

<style scoped>
.book-list {
  padding: 30px;
}

.book-item {
  height: 190px;
  display: flex;
  align-items: center;
  padding: 20px 30px 20px;
  margin-bottom: 20px;
  border-radius: 14px;
  background-color: #fff;
}

.book-cover {
  width: 130px;
  height: 178px;
  margin-right: 16px;
  border-radius: 8px;
  overflow: hidden;
}
.book-cover img {
  width: 100%;
  height: 100%;
}
.book-info {
  flex: 1;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.book-info .top {
  flex: 1;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.book-info .title {
  font-weight: bold;
  font-size: 26px;
  color: #292929;
}

.book-info .author {
  margin-top: 5px;
  font-weight: 400;
  font-size: 23px;
  color: #292929;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.des {
  margin-top: 10px;
  font-weight: 400;
  font-size: 23px;
  color: #292929;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 23px;
  height: 60px;
  max-height: calc(23px * 2); /* 行高 * 最大行数 */
}
.book-status {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #999;
  font-size: 23px;
  color: #292929;
  margin-top: 15px;
}
.book-status span{
  margin-bottom: 5px;
}
</style>
