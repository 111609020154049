<template>
  <div class="container-box">
    <div class="payment-form">
      <account-header @onClickBack="$router.go(-1)"></account-header>
      <div class="payment-method">
        <div>Use the payment method below to pay</div>
       <div class="binance-icon">
         <img src="@/assets/binance.png" alt="Binance Logo" class="payment-method-logo"/>
         <div>Binance</div>
       </div>
      </div>
      <div class="amount-selection">
        <span>Select Amount</span>
        <div class="select-amount-box">
          <div class="amount-item" v-for="(item, index) in selectAmountList" :key="index">
            <button @click="selectAmount(item.Money)" :class="{ selected: selectedAmount === item.Money}">
              ${{ item.Money }}<br>={{ item.balance }} reading coins
            </button>
          </div>
        </div>
      </div>
      <div class="notice-box" v-if="notice">
        <div class="notice-title">
<!--          <img src="@/assets/wow.png" alt="Good News" class="title-img"/>-->
          GOOD NEWS
        </div>
        <div>
          {{ notice }}
        </div>
      </div>
      <div class="custom-amount" v-if="showCustomAmount">
        <label for="custom-amount">Enter Amount:</label>
        <div>
          <input type="number" id="custom-amount" v-model.number="customAmount" @input="validateInput" readonly/>
        </div>
      </div>
      <button class="next-button" @click="nextStep">Next</button>
      <RemindPP :isVisible="isShowErrorPP" :closeable="true" @update:isVisible="onClickBack">
        <template v-slot:body>
          {{ initErrorMsg }}
        </template>
      </RemindPP>
    </div>
  </div>
</template>

<script>
import Request from "@/utils/request";
import RemindPP from "@/components/RemindPP.vue";
import AccountHeader from "@/components/AccountHeader.vue";

export default {
  components: { RemindPP, AccountHeader },
  data() {
    return {
      selectedAmount: 1,
      customAmount: 1,
      isShowErrorPP: false,
      isOrderInitError: false,
      initErrorMsg: "",
      selectAmountList: [],
      notice: '',
      showCustomAmount: false,
      orderInitCalled: false // 添加一个状态变量
    };
  },
  created() {
    if (!this.orderInitCalled) {
      this.orderInitCalled = true;
      this.orderInit();
    }
  },
  methods: {
    validateInput(event) {
      const value = event.target.value;
      this.customAmount = value.replace(/[^\d]/g, '');
      this.selectedAmount = 0;
    },
    selectAmount(amount) {
      this.selectedAmount = amount;
      this.customAmount = amount;
      this.showCustomAmount = false;
    },
    nextStep() {
      if (this.customAmount < 1) {
        return;
      }
      Request({
        url: '/api/recharge/create',
        method: 'post',
        data: {
          payMoney: parseInt(this.customAmount),
          returnUrl: '/payPage'
        }
      }).then(data => {
        // console.log(data)
        window.location.href = data.payUrl;
      }).catch(error => {
        this.isShowErrorPP = true;
        this.initErrorMsg = error.message;
      });
    },
    orderInit() {
      Request({
        url: '/api/recharge/init',
        method: 'post',
        data: {}
      }).then(data => {
        this.selectAmountList = data.selectAmount;
        this.notice = data.notice;
        if (data.status === 2) {
          this.isOrderInitError = true;
          this.isShowErrorPP = true;
          this.initErrorMsg = data.msg;
        } else {
          this.isOrderInitError = false;
          this.isShowErrorPP = false;
          this.initErrorMsg = '';
        }
      }).catch(error => {
        this.isShowErrorPP = true;
        this.initErrorMsg = error.message;
      });
    },
    onClickBack() {
      this.isShowErrorPP = false;
    }
  }
};
</script>

<style scoped>
.container-box {
  background-color: #F5F5F5;
  padding: 20px ;
}

.payment-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  color: #8F8F8F;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  overflow-y: auto;
}

.payment-method {
  display: flex;
  flex-direction: column;
  padding: 30px ;
  background: #FFFFFF;

  font-family: Open Sans, Open Sans;
  font-weight: 500;
  font-size: 28px;
  color: #333333;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  border-radius: 14px;
}

.payment-method-logo {
width: 60px;
height: 60px;
  margin-bottom: 15px;
}

.amount-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  margin-bottom: 50px;
  padding: 30px;
  margin-top: 30px;
  border-radius: 14px;
}

.amount-selection button {
  background-color: #F9EDE6;
  border: none;
  color: #5E2D11;
  height: 125px;
  cursor: pointer;
  flex: 1;
  border-radius: 10px;
  font-size: 25px;
  line-height: 50px;
}

.amount-selection button.selected {
  border: 2px solid #F65900;
  color: #F65900;
}

.amount-selection span {
  font-family: Open Sans, Open Sans;
  font-weight: 500;
  font-size: 28px;
  color: #333333;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  width: 100%;
  padding: 0px 0px 30px 0px;
}

.custom-amount {
  width: 100%;
  margin-bottom: 20px;
}

.custom-amount label {
  display: block;
  margin: 22px 0 22px 30px;
}

.custom-amount div {
  display: flex;
  width: 100%;
  height: 80px;
  background: #3A3A3A;
}

.custom-amount input {
  background: #3A3A3A;
  border: none;
  flex: 1;
  height: 100%;
  font-size: 24px;
  padding: 0 30px;
  color: white;
}

.next-button {
  background-color: #E5B496;
  border: none;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  margin: 0px 30px;
  color: white;
  font-size: 40px;
  height: 80px;
}

.select-amount-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.amount-item {
  display: flex;
}

.notice-box {
  background: #FFFFFF;
  margin-bottom: 50px;
  padding: 30px;
  color: #333333;
  font-size: 25px;
  border-radius: 14px;
}

.notice-title {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 30px;
  margin-bottom: 10px;
  color: #333333;
}

.title-img {
  height: 40px;
  margin-right: 10px;
}

.binance-icon {
  width: 245px;
  height: 131px;
  background: linear-gradient( 180deg, #FDF8F5 0%, #FCF3EE 100%);
  border-radius: 14px 14px 14px 14px;
  border: 1px solid #F9EDE6;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;

  font-family: Open Sans, Open Sans;
  font-weight: 400;
  font-size: 20px;
  color: #5E2D11;
  line-height: 24px;
  text-align: center;
  font-style: normal;
  text-transform: none;
  margin-top: 30px;
}
</style>
