<template>
  <div v-if="isVisible" class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
      <div class="modal-header">
        <slot name="header"></slot>
      </div>
      <div class="modal-body">
        <slot name="body"></slot>
      </div>
      <div class="modal-footer">
        <div @click="closeModal" >Close</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background: rgba(0, 0, 0, 0.5);*/
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 10px;
  padding: 50px 30px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: white; /* 文字白色 */
  background: rgba(0, 0, 0, 0.82); /* 半透明背景 */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-header{
  font-weight: 500;
  font-size: 36px;

}
.modal-body{
  width: 100%;
  font-size: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}
.modal-footer {
  padding: 10px 0px;
  font-size: 20px;
  color: white;
  width: 100%;
  text-align: center;
  background: rgba(255, 255, 255, 0.24);
  border-radius: 40px;
  margin-bottom: 10px;
}

</style>
