<template>
  <MyHeader/>
  <div class="book-details">
    <div class="book-cover">
      <img v-lazy="book.cover"/>
    </div>
  </div>

  <div class="action-buttons">
    <button v-if="book.buttonByReadTxt" @click="readBook"
            class="primary-button bnt-read-book">{{ book.buttonByReadTxt }}
    </button>
    <!--    <button v-if="book.buttonByBuyTxt" @click="buyChapters"
                class="plain-button">{{ book.buttonByBuyTxt }}
        </button>-->
  </div>
  <div class="book-summary">
    <!--    <div class="summarty">Summary</div>-->
    <div class="content">{{ book.descript }}</div>
  </div>
  <div class="book-info">
    <div class="title">{{ book.title }}</div>
    <div class="author">{{ book.author }}</div>
  </div>

</template>

<script>
import request from "@/utils/request";
import MyHeader from "@/components/AccountHeader.vue";

export default {
  name: "BookDetails",
  components: {MyHeader},
  data() {
    return {
      book: {},
      id: null,
      buyLoading: false,
      readLoding: false,
    };
  },
  created() {
    const {id} = this.$route.params;
    if (id) {
      this.id = id;
      this.fetchDetail();
    }
  },
  methods: {
    async fetchDetail() {
      try {
        const response = await request({
          url: "/api/novel/detail",
          method: "post",
          data: {id: this.id && Number(this.id)},
        });
        this.book = response;
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    },
    async readBook() {
      if (this.readLoding) return;
      try {
        this.readLoding = true;
        await request({
          url: "/api/novel/view",
          method: "post",
          data: {id: this.id && Number(this.id)},
        });
        this.$router.push(`/readBook/${this.id}/${this.book.orderBy}`);
      } catch (error) {
        this.readLoding = false;
      }
    },
    async buyChapters() {
      if (this.buyLoading) return;
      try {
        this.buyLoading = true;
        const res = await request({
          url: "/api/order/create",
          method: "post",
          data: {nid: this.id && Number(this.id), is_all: 1},
        });
        window.location.href = res.payUrl;
        this.buyLoading = false;
      } catch (error) {
        this.buyLoading = false;
        console.error("Error fetching books:", error);
      }
    },
  },
};
</script>

<style scoped>
.book-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 30px;
  padding: 20px 20px 26px;
  background: #ffffff;
  border-radius: 14px;
}

.book-card {
  height: 178px;
  display: flex;
  padding: 20px 30px 20px;
  margin-bottom: 20px;
  border-radius: 14px;
  background-color: #fff;
}

.book-cover {
  width: 430px;
  height: 588px;
  border-radius: 8px;
  overflow: hidden;
}

.book-cover img {
  width: 100%;
  height: 100%;
}

.book-info {
  flex: 1;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 50px;
  margin: 30px;
  align-items: center;
  padding: 17px;
}

.book-info .title {
  font-weight: bold;
  font-size: 30px;
  color: #292929;
}

.book-info .author {
  margin-top: 5px;
  font-weight: 400;
  font-size: 26px;
  color: #292929;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.book-chapters .num {
  font-weight: bold;
  font-size: 26px;
  color: #292929;
  margin-bottom: 5px;
}

.book-summary  {
 margin-left: 30px;
 margin-right: 30px;
 margin-bottom: 30px;
  font-weight: bold;
  font-size: 26px;
  color: #292929;
  padding-left: 5px;
  padding-right: 5px;


  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.book-summary .content {
  font-weight: 400;
  font-size: 26px;
  color: #292929;

}

.bnt-read-book {
  padding: 0px 0px;
  margin: 0;
}
</style>
