<template>
  <div class="empty-wrap">
    <img src="@/assets/empty.png" alt="">
  </div>
</template>
<script>
export default {
  name: "EmptyCard",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style  scoped>
.empty-wrap {
  margin: auto;
  width: 356px;
}
.empty-wrap img {
  width: 100%;
}
</style>
