<template>
  <div class="user-page">
    <MyHeader :isAvater="false"/>
    <div class="user-card">
      <!--      <div class="avatar">{{ this.fname }}</div>-->
      <div class="user-info-text">
        <div class="user-info-box" @click="refrenshUserInfo">
          <div><label>User name:</label>{{ userInfo.googleEmail }}</div>
          <img src="@/assets/refrensh.png" class="refresh" />
        </div>
        <div><label>User ID:</label> {{ userInfo.userId }}</div>
        <div class="user-id">Reading Coins: {{ userInfo.balance }}
          <div class="user-more" @click="onClickMore()"> MORE>></div>
        </div>
      </div>
      <button class="next-button" @click="onClickBuy()">Buy Reading Coins</button>
    </div>
    <van-tabs v-model:active="activeTab" shrink background="#F5F5F5" title-active-color="#292929"
              title-inactive-color="#292929" @change="onClickTab">
      <van-tab :title="tab1.title" title-class="tab">
        <div v-if="tab1.list.length>0" class="list1">
          <van-list v-model:loading="tab1.loading" :finished="tab1.finished"
                    finished-text="You've reached the end" @load="onLoad(tab1)">
            <div class="list-item" v-for="(item1, index) in tab1.list" :key="index"
                 @click="toDetail(item1)">
              <div class="list-cover">
                <img v-lazy="item1.cover"/>
              </div>
              <div class="list-info">
                <div class="top">
                  <div class="title">{{ item1.title }}</div>
                  <div class="author">Updated to Chapter：{{ item1.lastChapter }}</div>
                </div>
                <div class="list-status">
                  <div>Last Read Chapter：{{ item1.orderBy }}</div>
                  <div>Last Read Time：{{ item1.lastReadTime }}</div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
        <div v-else class="empty">
          <EmptyCard/>
        </div>
      </van-tab>
      <van-tab :title="tab2.title" title-class="tab">
        <div v-if="tab2.list.length>0" class="list2">
          <van-list v-model:loading="tab2.loading" :finished="tab2.finished"
                    finished-text="You've reached the end" @load="onLoad(tab2)">
            <div class="list-item" v-for="(item) in tab2.list" :key="item.id">
              <div class="title">Order ID: {{ item.orderNumber }}</div>
              <div class="weight">Novel：{{ item.title }}</div>
              <div class="weight">Chapter：{{ item.content }}</div>
              <div class="weight">Reading Coins：{{ item.payMoney }}</div>
              <div class="weight">Time：{{ item.createdView }}</div>
            </div>
          </van-list>
        </div>
        <div v-else class="empty">
          <EmptyCard/>
        </div>
      </van-tab>
      <van-tab :title="tab3.title" title-class="tab">
        <div v-if="tab3.list.length>0" class="list2">
          <van-list v-model:loading="tab3.loading" :finished="tab3.finished"
                    finished-text="You've reached the end" @load="onLoad(tab3)">
            <div class="list-item" v-for="(item) in tab3.list" :key="item.id">
              <div class="title">Order ID: {{ item.orderNumber }}</div>
              <div class="weight">Amount：{{ item.payMoney }}</div>
              <div class="weight">Reading Coins：{{ item.balance }}</div>
              <!--              <div class="weight">Money：{{ item.payMoney }}</div>-->
              <div class="weight">Time：{{ item.createdView }}</div>
              <div class="weight">Status：{{ item.statusView }}</div>
              <div class="weight">Note：{{ item.note }}</div>
            </div>
          </van-list>
        </div>
        <div v-else class="empty">
          <EmptyCard/>
        </div>
      </van-tab>
    </van-tabs>
    <RemindPP ref="remindModal">
      <!--    <template v-slot:header>
            这是提示标题
          </template>-->
      <template v-slot:body>
        {{ remind_info }}
      </template>
    </RemindPP>
  </div>

</template>
<script>
import request from "@/utils/request";
import MyHeader from "@/components/AccountHeader.vue";
import EmptyCard from "@/components/EmptyCard.vue";
import RemindPP from "@/components/RemindPP";
import userService from "@/utils/userService";

export default {
  name: "UserPage",
  components: {MyHeader, EmptyCard, RemindPP},
  data() {
    return {
      activeTab: 0,
      tab1: {title: "Reading History", api: "/api/read/log", list: [], page: 1, loading: true, finished: false},
      tab2: {title: "Buy History", api: "/api/order/list", list: [], page: 1, loading: true, finished: false},
      tab3: {title: "Recharge History", api: "/api/recharge/list", list: [], page: 1, loading: true, finished: false},
      userInfo: {
        userId: null,
        googleEmail: null,
        balance: null,
      },
      remind_info: ""
    };
  },
  created() {
    // this.loadUserData();
    this.fetchData(this.tab1);
    this.refrenshUserInfo();
  },
  methods: {
    async refrenshUserInfo() {
      const res = await request.post("/api/user/data");
      this.userInfo.userId = res.id;
      this.userInfo.googleEmail = res.google;
      this.userInfo.balance = res.balance;
      localStorage.setItem("user", JSON.stringify(res));
    },
    async fetchData(currentTab) {
      // const currentTab = this.tabs[this.activeTab];
      currentTab.loading = true;

      try {
        const res = await request.post(currentTab.api, {page: currentTab.page});
        if (res && res.items) {
          currentTab.list = currentTab.list.concat(res.items);
        }
        currentTab.finished = !res.next;
      } catch (error) {
        console.error(`Error fetching data for ${currentTab.title}:`, error);
      } finally {
        currentTab.loading = false;
      }
    },
    async onLoad(tab) {
      tab.page += 1;
      await this.fetchData(tab);
    },
    onClickTab() {
      let tab = this.tab1;
      if (this.activeTab == 0) {
        tab = this.tab1;
      } else if (this.activeTab == 1) {
        tab = this.tab2;
      } else {
        tab = this.tab3;
      }
      tab.page = 1;
      tab.list = []
      this.fetchData(tab);
    },
    /* loadUserData() {
       const user = JSON.parse(localStorage.getItem("user") || '{}');
       this.userInfo.userId = user.id;
       this.userInfo.googleEmail = user.google;
       this.userInfo.balance = user.balance;
     },*/
    onClickMore() {
      this.$router.push(`/userInfoDetailPage/`);
    },
    onClickBuy() {
      this.$router.push(userService.isTG() ? `/telegramPay/` : `/payPage/`);
    },
    toDetail(item) {
      if (item.notice) {
        this.remind_info = item.notice;
        this.$refs.remindModal.openModal();
      } else {
        this.$router.push(`/bookDetails/${item.nid}`);
      }
    }
  }
};
</script>
<style scoped>
.user-card {
  margin: 30px;
  background: #ffffff;
  border-radius: 14px;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.user-card .avatar {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  background: #b589ff;
  font-weight: bold;
  font-size: 27px;
  color: #ffffff;
  text-align: center;
  margin-right: 20px;
}

.user {
  font-weight: bold;
  font-size: 23px;
  color: #292929;
  padding: 0px 0px 5px 0px;
}

.list1 {
  padding: 30px;
}

.list1 .list-item {
  height: 178px;
  display: flex;
  padding: 20px 30px 20px;
  margin-bottom: 20px;
  border-radius: 14px;
  background-color: #fff;
}

.list1 .list-cover {
  width: 130px;
  height: 178px;
  margin-right: 16px;
  border-radius: 8px;
  overflow: hidden;
}

.list1 .list-cover img {
  width: 100%;
  height: 100%;
}

.list1 .list-info {
  flex: 1;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list1 .list-info .top {
  flex: 1;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.list1 .list-info .title {
  font-weight: bold;
  font-size: 26px;
  color: #292929;
}

.list1 .list-info .author {
  margin-top: 5px;
  font-weight: 400;
  font-size: 23px;
  color: #292929;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list1 .list-info .des {
  margin-top: 19px;
  font-weight: 400;
  font-size: 18px;
  color: #292929;
  flex: 1;
  flex-shrink: 0;
  overflow: hidden;
}

.list1 .list-status {
  margin-top: 15px;
  font-weight: 400;
  font-size: 23px;
  color: #292929;
}

.list1 .list-status > div:first-child {
  margin-bottom: 19px;
}

.list2 {
  padding: 30px;
}

.list2 .title {
  font-weight: bold;
  font-size: 26px;
  color: #292929;
  margin-bottom: 20px;
}

.list2 .weight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 23px;
  color: #292929;
  margin-top: 10px;
}

.list2 .sub {
  margin-top: 13px;
  font-weight: 400;
  font-size: 18px;
  color: #747474;
}

.list2 .list-item {
  background: #ffffff;
  border-radius: 14px;
  padding: 20px;
  margin-bottom: 20px;
}

.empty {
  margin-top: 110px;
}

.user-page :deep(.van-tabs__wrap) {
  padding: 0 30px;
}

.user-page :deep(.tab) {
  font-weight: bold;
  font-size: 23px;
  color: #292929;
  margin-right: 10px;
}

.user-page :deep(.van-tabs__line) {
  background-color: #1781b5;
  border-radius: 4px;
  width: 120px;
  height: 8px;
}

.user-page :deep(.van-tabs__wrap) {
  height: 60px;
}

.user-info-box {
  display: flex;
  justify-content: space-between;
}

.user-info-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 23px;
  color: #333333;
  line-height: 40px;
  background: #F5F5F5;
  width: 100%;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;

}

.user-info-text label {
  color: #999999;
  margin-left: 15px;

}


.user-id {
  display: flex;
  font-weight: bold;
  font-size: 23px;
  color: #9555FF;
  padding: 0px 0px 5px 0px;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
}

.user-more {
  display: flex;
  font-weight: bold;
  font-size: 23px;
  color: #1781B5;
  margin-left: auto;
}

.next-button {
  width: 100%;
  height: 70px;
  border: none;
  background-color: #1781B5;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 30px;

  font-size: 28px;
}


.refresh {
  width: 25px;
  height: 25px;
  margin: 10px;
}
</style>

