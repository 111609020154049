<template>
  <MyHeader/>
  <div v-if="isRouterAlive">
    <div class="current">
      Chapter {{ book.orderBy }}&nbsp;
      <div v-if="book.title"> {{ book.title }}</div>
    </div>
    <div class="chapter">
      {{ book.content }}
    </div>

    <div v-if="!book.isBuy" class="action-buttons">
      <button v-if="book.nextOrderBy" @click="nextChapters" class="primary-button">Next
        Chapter
      </button>
    </div>
    <div class="action-buttons" v-else>
      <button @click="buyChapters" class="purple-button">Buy This Chapter:{{ book.price }} Reading Coins</button>
    </div>
    <div class="action-buttons">
      <button v-if="book.prevOrderBy" @click="preChapters" class="plain-button">Previous
        Chapter
      </button>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
import MyHeader from "@/components/AccountHeader.vue";
import {showDialog} from "vant";

export default {
  components: {MyHeader},
  props: {},
  data() {
    return {
      id: null,
      book: {},
      orderBy: null,
      isRouterAlive: true,
    };
  },
  computed: {},
  watch: {
    "$route.params.orderBy"() {
      this.init();
      this.reload();
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const {id, orderBy} = this.$route.params;
      if (id) {
        this.id = id;
        this.orderBy = orderBy;
        this.fetchDetail();
      }
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    async fetchDetail() {
      try {
        const response = await request({
          url: "/api/novel/chapter/detail",
          method: "post",
          data: {
            nid: this.id && Number(this.id),
            orderBy: this.orderBy && Number(this.orderBy),
          },
        });
        this.book = response;
        if (response.msg) {
          showDialog({
            className: "err-wrap",
            message: response.msg,
            confirmButtonText: "Close",
          }).then(() => {
            // on close
          });
        }
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    },
    nextChapters() {
      this.$router.replace(`/readBook/${this.id}/${this.book.nextOrderBy}`);
    },
    preChapters() {
      this.$router.replace(`/readBook/${this.id}/${this.book.prevOrderBy}`);
    },
    async buyChapters() {
      if (this.buyLoading) return;
      try {
        this.buyLoading = true;
        const res = await request({
          url: "/api/order/create",
          method: "post",
          data: {
            nid: this.id && Number(this.id),
            is_all: 0,
            cid: this.book && Number(this.book.id),
          },
        });
        console.log(res)
        // window.location.href = res.payUrl;
        window.location.reload();
        this.buyLoading = false;
      } catch (error) {
        this.buyLoading = false;
        console.error("Error fetching books:", error);
        if (error.response && error.response.data) {
          const { code, message } = error.response.data;
          // console.error(`Error Code: ${code}, Message: ${message}`);
          if (code === 4001) {
            showDialog({
              className: "err-wrap",
              title: "",
              message: message,
              confirmButtonText: "Buy Reading Coins",
              cancelButtonText: "Close",
              showCancelButton: true,
            }).then(() => {
              // 用户点击了“去充值”
              // this.$router.push("/recharge"); // 这里你可以导航到充值页面
            }).catch(() => {
              // 用户点击了“取消”
              console.log("user cancel");
            });
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.current {
  /*height: 120px;*/
  line-height: 40px;
  background: #ffffff;
  font-weight: bold;
  font-size: 26px;
  color: #292929;
  padding-left: 30px;
  width: 100%;
  position: fixed;
  top: 100px;
  left: 0;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.chapter {
  padding: 50px;
  font-weight: 400;
  font-size: 26px;
  color: #292929;
  line-height: 35px;
  margin-top: 70px;
  white-space: pre-wrap;
}

.purple-button {
  height: 70px;
  line-height: 70px;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
  display: block;
  width: 100%;
  background: #333333;
  color: #fff;
}

.purple-button:active {
  background: #333333;
}
</style>
<style>
.err-wrap {
  width: 450px !important;
}

.err-wrap .van-dialog__message {
  font-size: 31px !important;
  padding-top: 40px;
  padding-bottom: 40px;
  line-height: 50px !important;
}

.err-wrap .van-button {
  font-size: 29px !important;
  height: 60px !important;
  line-height: 60px !important;
}
.plain-button {
  height: 70px;
  line-height: 70px;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
  display: block;
  width: 100%;
  background: #6DBAEE;
  color: white;
}
</style>
