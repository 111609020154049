// src/router/index.js
import { createRouter, createWebHistory } from "vue-router";
// import { isAuthenticated } from "@/js/auth";
import HomePage from "../views/HomePage.vue";
// import LoginPage from "../views/GoogleLogin.vue";
import LoginPage from "../views/login/EmailLoginPage.vue";
import BookDetails from "../views/BookDetails.vue";
import Oauth2Callback from "../views/Oauth2Callback.vue";
import User from "../views/User.vue";
import ReadBook from "../views/ReadBook.vue";
import ServiceText from "../views/ServiceText.vue";
import PrivacyText from "../views/PrivacyText.vue";
import TestLogin from "../views/TestLogin.vue";
import UserInfoDetailPage from "../views/UserInfoDetailPage.vue";
import ForgotPasswordPage from "../views/ForgotPasswordPage.vue";
import UpdatePwdPage from "../views/UpdatePwdPage.vue";
import PayPage from "../views/order/PayPage.vue";
import TelegramPay from "../views/order/TelegramPay.vue";
import userService from "@/utils/userService";
const routes = [
  {
    path: '/',
    redirect: '/homePage'
  },
  {
    path: '/index',
    redirect: '/homePage',

  },
  {
    path: "/homePage",
    name: "HomePage",
    component: HomePage,
    meta: {
      keepAlive: true,
    },
    // meta: { requiresAuth: true }, // 标记需要登录才能访问的页面
  },

  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/bookDetails/:id",
    name: "BookDetails",
    component: BookDetails,
  },
  {
    path: "/oauth2callback",
    name: "Oauth2Callback",
    component: Oauth2Callback,
  },
  {
    path: "/user",
    name: "User",
    component: User,
    // meta: {
    //   keepAlive: true,
    // },
  },
  {
    path: "/readBook/:id/:orderBy",
    name: "ReadBook",
    component: ReadBook,
  },
  {
    path: "/serviceText",
    name: "ServiceText",
    component: ServiceText,
  },
  {
    path: "/privacyText",
    name: "PrivacyText",
    component: PrivacyText,
  },


  {
    path: "/testLogin",
    name: "TestLogin",
    component: TestLogin,
  },
    {
    path: "/userInfoDetailPage",
    name: "UserInfoDetailPage",
    component: UserInfoDetailPage,
  },
  {
    path: "/forgotPasswordPage",
    name: "ForgotPasswordPage",
    component: ForgotPasswordPage,
  },
  {
    path: "/updatePwdPage",
    name: "UpdatePwdPage",
    component: UpdatePwdPage,
  },
  {
    path: "/payPage",
    name: "PayPage",
    component: PayPage,
  },
  {
    path: "/telegramPay",
    name: "TelegramPay",
    component: TelegramPay,
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 全局路由守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/homePage') {
    // 如果当前路径是 /homepage，执行一些逻辑
    console.log('当前路径是 HomePage');
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('token')&&userService.isTG()) {
      let access_token = urlParams.get("token");
      userService.tokenLogin(access_token)
      // let platform_os = urlParams.get("os");
      // userService.setPlatform_os(platform_os)
    }
    // 执行完成后继续导航
    next();
  } else {
    // 不需要身份验证的页面，直接访问
    next();
  }
});
export default router;
