<template>
  <div class="container-box">
    <account-header @onClickBack="$router.go(-1)"></account-header>
    <div class="payment-form">
      <div class="payment-title">This is your personal account</div>
      <div class="payment-des">Deposit to this address and the amount will be credited</div>
      <img class="img-qrcode" :src="data.qrcode">
      <div class="payment-walletAddress">{{data.walletAddress}}</div>
      <div class="payment-copy" @click="copyCode">Click to copy</div>
      <div class="notice-box" v-if="data.notice&&data.notice">
        <div class="notice-title">
<!--          <img src="@/assets/wow.png" alt="Good News" class="title-img"/>-->
          Good News
        </div>
        <div class="notice-text" >
          {{ data.notice }}
        </div>
      </div>
    </div>
    <RemindPP :isVisible="isShowErrorPP" :closeable="true" @update:isVisible="onClickBack">
      <template v-slot:body>
        {{ initErrorMsg }}
      </template>
    </RemindPP>
  </div>
</template>

<script>
import Request from "@/utils/request";
import RemindPP from "@/components/RemindPP.vue";
import AccountHeader from "@/components/AccountHeader.vue";
import { useClipboard } from '@vueuse/core'

export default {
  components: {RemindPP, AccountHeader},
  data() {
    return {
      isShowErrorPP: false,
      initErrorMsg: "",
      data: {

        notice: '1.The more you recharge, the more game coins you will receive as gifts.\n' +
            '2.The deposit will arrive in 10 minutes.\n' +
            '3.lf you have any questions, Please contact customer services.',
        qrcode: '',
        walletAddress: '',
      }
    };
  },
  created() {
    this.orderInit();
  },
  methods: {
    validateInput(event) {
      const value = event.target.value;
      this.customAmount = value.replace(/[^\d]/g, '');
      this.selectedAmount = 0;
    },
    selectAmount(amount) {
      this.selectedAmount = amount;
      this.customAmount = amount;
      this.showCustomAmount = false;
    },
    copyCode() {
      if (window.clipboardData) {
        window.clipboardData.setData('text', this.data.walletAddress);
      }else {
        const {copy } = useClipboard({ legacy: true })
        copy( this.data.walletAddress)
      }
      alert('Copy Success');
      console.log('Copy Successed');
    },
    orderInit() {
      Request({
        url: '/telegram/order/init',
        method: 'post',
        data: {type: 0}
      }).then(data => {
        this.data.qrcode = data.qrcode
        this.data.walletAddress = data.walletAddress
        // this.data.notice= data.notice
      }).catch(error => {
        this.isShowErrorPP = true;
        this.initErrorMsg = error.message;
      });
    },
    onClickBack() {
      this.isShowErrorPP = false;
    }
  }
};
</script>

<style scoped>
.container-box {
  position: fixed;
  background-color: #1a1a1a;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.payment-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  overflow-y: auto;
}


.notice-box {
  background: #282828;
  margin-bottom: 50px;
  padding: 30px;
  color: #D7D7D7;
  font-size: 25px;
}

.notice-title {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 30px;
  margin-bottom: 10px;
}

.title-img {
  height: 40px;
  margin-right: 10px;
}

.img-qrcode {
  width: 390px;
  height: 390px;
  margin-top: 30px;
}

.payment-title {

  font-family: PingFang SC;
  font-weight: bold;
  font-size: 26px;
  color: #FFFFFF;
  line-height: 30px;
  text-stroke: 1px #333333;
  -webkit-text-stroke: 1px #333333;
  margin-top: 40px;
}

.payment-des {
  margin-top: 18px;
}

.payment-walletAddress {

  font-family: PingFang SC;
  font-weight: 500;
  font-size: 22px;
  color: #FFFFFF;

  margin-top: 15px;
}

.payment-copy {
  width: 268px;
  height: 60px;
  background: #15BB4F;
  border-radius: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 26px;
  color: #FFFFFF;
  line-height: 30px;
  text-align: center;
  line-height: 60px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.notice-text {
  white-space: pre-wrap;
  line-height: 35px;
}
</style>
