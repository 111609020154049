import axios from "axios";
import { showToast } from "vant";
let withoutMsg = false; //取消报错提醒
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
  timeout: 5000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    const access_token = localStorage.getItem("token");
    if (access_token) {
      config.headers = {
        "x-token": access_token,
        ...config.headers,
      };
    }
    withoutMsg = config.withoutMsg;
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (!res) {
      showToast({
        message: "http错误",
        forbidClick: true,
      });
      return Promise.reject(response);
    }
    if (withoutMsg) {
      return res;
    } else if (res.code === 9999) {
      // showToast({
      //   message: res.message || "Error",
      //   forbidClick: true,
      //   onClose: () => {
      localStorage.removeItem("token");
      // window.location = "/login";
        window.location = `/login?redirect=${encodeURIComponent(window.location.pathname)}`;
      //   },
      // });


      return Promise.reject(res);
    } else if (res.code !== 1000) {
      console.log(res);
      showToast({
        message: res.message || "Error",
        forbidClick: true,
      });
      return Promise.reject(res);
    } else {
      return res.data;
    }
  },
  (error) => {
    console.log("err：" + error); // for debug
    showToast({
      message: error.message,
      forbidClick: true,
    });
    return Promise.reject(error);
  }
);

// JSON axios

export default service;
