<template>
  <div></div>
</template>
<script>
import request from "@/utils/request";
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {
    const query = this.$route.query;
    if (query.state && query.code) {
      this.postState(query.state, query.code);
    }
  },
  methods: {
    async postState(state, code) {
      try {
        const response = await request.post("/google/callback", {
          state,
          code,
        });
        localStorage.setItem("token", response.token);
        const res = await request.post("/api/user/data");
        localStorage.setItem("user", JSON.stringify(res));
        this.$router.replace("/");
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
