<template>
  <div class="header">
    <div class="header-left">
      <div class="logo" @click="toHome">
        <img src="@/assets/logo.png" alt="Daily Reading Logo" />
      </div>
      <div class="header-text">
        <div class="title">Daily Reading</div>
        <div class="des">Reading is the destination of our souls</div>
      </div>
    </div>
    <div class="profile-icon">
      <img v-if="!isLogin" src="@/assets/avatar.png" alt="Profile Icon" @click="toLogin" />
      <div v-else-if="isAvater" class="avatar" @click="toUser">{{ this.fname }}</div>
    </div>
  </div>
  <div class="block"></div>
</template>
<script>
export default {
  name: "AccountHeader",
  components: {},
  props: {
    isAvater: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {
    isLogin() {
      return !!localStorage.getItem("token");
    },
    fname() {
      const user = localStorage.getItem("user");
      const google = user && JSON.parse(user).google;
      const regex = /[A-Za-z]/;
      let match;

      // 使用正则表达式在字符串中查找
      while ((match = regex.exec(google))) {
        // 返回找到的第一个英文字母
        const fname = match[0] && match[0].toUpperCase();
        console.log(fname);
        return fname;
      }
      return "";
    },
  },
  created() {},
  methods: {
    toUser() {
      this.$router.push({
        name: "User",
      });
    },
    toLogin() {
      // this.$router.push("/login");
      this.$router.push({ path: '/login', query: { redirect: this.$router.currentRoute.value.fullPath } });

    },
    toHome() {
      this.$router.push("/");
    },
  },
};
</script>
<style scoped>
.block {
  height: 100px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  background-color: #1781b5;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 999;
}
.header-left {
  display: flex;
  flex: 1;
  align-items: center;
}
.logo {
  width: 64px;
  height: 64px;
}
.logo img {
  width: 100%;
  height: 100%;
}

.header-text {
  margin-left: 15px;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-text .title {
  /* margin-top: 7px;
  margin-bottom: 15px; */
  font-size: 27px;
  color: #ffffff;
}

.header-text .des {
  font-size: 21px;
  color: #ffffff;
}

.profile-icon {
  width: 68px;
  height: 68px;
  border-radius: 50%;
}

.profile-icon img {
  width: 100%;
  height: 100%;
}

.profile-icon .avatar {
  width: 68px;
  height: 68px;
  line-height: 68px;
  border-radius: 50%;
  background: #b589ff;
  font-weight: bold;
  font-size: 32px;
  color: #ffffff;
  text-align: center;
}
</style>
