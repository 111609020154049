<template>
  <div></div>
</template>
<script>
import request from "@/utils/request";

export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {
    this.postState()
  },
  methods: {
    async postState() {
      try {
        let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTkyMTM0MjMsInVzZXJJRCI6NH0.HLoB1pwlH5g-dkTYin8pvP7q5JvcpH9B9KrOATZ6AHQ"
        /*const response = await request.post("/google/callback", {
          state,
          code,
        });*/
        localStorage.setItem("token", token);
        const res = await request.post("/api/user/data");
        localStorage.setItem("user", JSON.stringify(res));
        this.$router.replace("/");
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
