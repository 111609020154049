// src/main.js
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "amfe-flexible";
// vant
import { List, Button, Tab, Tabs } from "vant";
import "vant/lib/index.css";
// 引入英文语言包
import { Locale } from "vant";
import enUS from "vant/es/locale/lang/en-US";
// 图片懒加载
import VueLazyload from "vue-lazyload";
const loadimage = require("@/assets/default.png");

Locale.use("en-US", enUS);
const app = createApp(App);
app.use(router);
app.mount("#app");
app.use(List);
app.use(Button);
app.use(Tab);
app.use(Tabs);

app.use(VueLazyload, {
  // 设置默认图片
  loading: loadimage,
  // 设置错误图片
  error: loadimage,
});
