<template>
  <div class="daily-reading">
    <div class="content">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Daily Reading Logo"/>
      </div>
      <div class="title-text">Daily Reading</div>
      <p>Reading is the destination of our souls</p>
      <div class="form-group">
        <div class="input-top-text">E-mail</div>
        <div class="input-box"><input
            type="email"
            v-model="email"
            placeholder="Enter your Email"
            class="form-control email-input"
        /></div>

        <div class="input-top-text">Password</div>
        <div class="verification input-box">
          <input
              type="text"
              v-model="password"
              placeholder="Enter your password, at least 6 characters"
              class="form-control code-input"
          />
<!--          <button @click="sendCode" :disabled="isSending" class="send-button">
            {{ buttonText }}
          </button>-->
        </div>
        <button @click="registerOrLogin" class="register-button">Register or Login</button>
        <div @click="onClickForgotPassword" class="forgot-pwd-text">Forgot your password？</div>
      </div>
    </div>
    <div class="footer">
      <div>© 2024 Daily Reading</div>
      <div>Conditions of service | Privacy statement</div>
    </div>
  </div>
</template>

<script>
import {ref, computed, onBeforeUnmount} from 'vue';
import request from "@/utils/request";
import {useRouter,useRoute} from 'vue-router';

export default {
  setup() {
    const email = ref('');
    const password = ref('');
    const isSending = ref(false);
    const countdown = ref(60);
    const router = useRouter();
    const route = useRoute();
    let timer = null;

    const buttonText = computed(() => (isSending.value ? `${countdown.value}s` : 'Send'));

    const sendCode = async () => {
      if (!email.value) {
        alert('Please enter your email');
        return;
      }
      isSending.value = true;
      try {
        const response = await request({
          url: "/api/sendmail",
          method: "post",
          data: {email: email.value},
        });
        if (response) {
          // 开始倒计时
          startCountdown();
        }
      } catch (error) {
        console.error('Error sending code:', error);
        isSending.value = false;
      }
    };

    const startCountdown = () => {
      timer = setInterval(() => {
        if (countdown.value > 0) {
          countdown.value--;
        } else {
          isSending.value = false;
          countdown.value = 60;
          clearInterval(timer);
        }
      }, 1000);
    };

    const registerOrLogin = async () => {
      // 实现注册或登录逻辑
      try {
        const response = await request({
          url: "/api/login/password",
          method: "post",
          data: {
            account: email.value,
            password: password.value
          },
        });
        console.log("======")
        console.log(response)
        if (response) {
          localStorage.setItem("token", response.token);
          const res = await request.post("/api/user/data");
          localStorage.setItem("user", JSON.stringify(res));
          // router.replace("/");
       /*   router.replace('/').then(() => {
            window.location.reload();
          });*/
          const redirectTo = route.query.redirect || '/';
          router.replace(redirectTo).then(() => {
            window.location.reload();
          });
        }
      } catch (error) {
        console.error('Error sending code:', error);
        isSending.value = false;
      }
    };
    const onClickForgotPassword= () => {
      router.push(`/forgotPasswordPage`);
    };

    onBeforeUnmount(() => {
      if (timer) {
        clearInterval(timer);
      }
    });

    return {
      email,
      password,
      isSending,
      countdown,
      buttonText,
      sendCode,
      registerOrLogin,
      onClickForgotPassword
    };
  },
};
</script>

<style scoped>
.daily-reading {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  font-family: Arial, sans-serif;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  padding: 20px;
  box-sizing: border-box;
  flex: 1;
}

.logo img {
  width: 120px;
  margin-bottom: 10px;

}

.title-text {
  font-size: 32px;
  font-weight: bold;
}

.input-top-text {
  font-weight: bold;
  margin-bottom: 10px;
  color: #292929;
  font-size: 25px;
}

p {
  font-size: 25px;
  margin-bottom: 40px;
  color: #292929;
  margin-bottom: 60px;
  margin-top: 10px;
}

.form-group {
  width: 90%;
  max-width: 500px;
}

.form-control {
  width: 100%;
  padding: 18px;
  margin-bottom: 20px;
  border-radius: 40px;
  border: none;
  box-sizing: border-box;
  font-size: 18px;
  background-color: transparent; /* 背景色设置为透明 */

}

.email-input {
  height: 80px;
  margin: 0px 0px 0px 0px;
  font-size: 23px;
  padding: 0px 30px;
}

.verification {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.code-input {
  margin-right: 20px;
  height: 80px;
  margin: 0px 0px 0px 0px;
  font-size: 23px;
  padding: 0px 30px;
}

.send-button {
  padding: 13px 20px;
  border: none;
  border-radius: 23px;
  background-color: #1781B5;
  color: white;
  cursor: pointer;
  font-size: 18px;
  margin-right: 20px;
}

.send-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.register-button {
  width: 100%;

  margin-top: 30px;
  border: none;
  border-radius: 40px;
  background-color: #1781B5;
  color: white;
  cursor: pointer;
  font-size: 23px;
  height: 80px;
}

.footer {
  text-align: center;
  font-size: 18px;
  color: #aaa;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.footer a {
  color: #292929;
  text-decoration: none;
  font-size: 18px;
}

.input-box {
  border-radius: 40px;
  background-color: #F1F1F1;
  margin-bottom: 30px;


}

.forgot-pwd-text {
  width:auto;
  height: 24px;
  font-family: Open Sans, Open Sans;
  font-weight: 400;
  font-size: 24px;
  color: #000000;
  line-height: 30px;
  text-align: center;
  font-style: normal;
  text-transform: none;
  margin-top: 20px;
  font-weight: bold;
}
</style>
