<template>
  <div class="page">
    <div class="title">Terms of Service</div>
    <div class="title">Web Site Terms and Conditions of Use</div>
    <p>1. Terms</p>
    <p>By accessing this web site, you are agreeing to be bound by these web site Terms and
      Conditions of Use, all applicable laws and regulations, and agree that you are responsible for
      compliance with any applicable local laws. If you do not agree with any of these terms, you
      are prohibited from using or accessing this site. The materials contained in this web site are
      protected by applicable copyright and trade mark law.</p>
    <p>2. Disclaimer</p>
    <p>The materials on Daily Reading’s web site are provided “as is”. Daily Reading makes no
      warranties, expressed or implied, and hereby disclaims and negates all other warranties,
      including without limitation, implied warranties or conditions of merchantability, fitness for
      a particular purpose, or non-infringement of intellectual property or other violation of
      rights. Further, Daily Reading does not warrant or make any representations concerning the
      accuracy, likely results, or reliability of the use of the materials on its Internet web site
      or otherwise relating to such materials or on any sites linked to this site.</p>
    <p>3. Limitations</p>
    <p>In no event shall Daily Reading or its suppliers be liable for any damages (including,
      without limitation, damages for loss of data or profit, or due to business interruption,)
      arising out of the use or inability to use the materials on Daily Reading’s Internet site,
      even if Daily Reading or a Daily Reading authorized representative has been notified orally or
      in writing of the possibility of such damage. Because some jurisdictions do not allow
      limitations on implied warranties, or limitations of liability for consequential or incidental
      damages, these limitations may not apply to you.</p>
    <p>4. Revisions and Errata</p>
    <p>The materials appearing on Daily Reading’s web site could include technical, typographical,
      or photographic errors. Daily Reading does not warrant that any of the materials on its web
      site are accurate, complete, or current. Daily Reading may make changes to the materials
      contained on its web site at any time without notice. Daily Reading does not, however, make
      any commitment to update the materials.</p>
    <p>5. Links</p>
    <p>Daily Reading has not reviewed all of the sites linked to its Internet web site and is not
      responsible for the contents of any such linked site. The inclusion of any link does not imply
      endorsement by Daily Reading of the site. Use of any such linked web site is at the user’s own
      risk.</p>
    <p>6. Site Terms of Use Modifications</p>
    <p>Daily Reading may revise these terms of use for its web site at any time without notice. By
      using this web site you are agreeing to be bound by the then current version of these Terms
      and Conditions of Use.</p>
    <p>7. Governing Law</p>
    <p>Any claim relating to Daily Reading’s web site shall be governed by the laws of the State of
      Hong Kong without regard to its conflict of law provisions.</p>
    <p>General Terms and Conditions applicable to Use of a Web Site.</p>
  </div>
</template>
<script>
export default {
  name: "ServiceText",
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style scoped>
.page {
  padding: 30px;
}
.title {
  font-size: 28px;
  text-align: center;
}
p {
  margin: 20px 0 0;
  font-size: 22px;
  text-align: justify;
}
</style>
