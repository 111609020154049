<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" v-if="$route.meta.keepAlive" />
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive" />
    </router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
</style>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.action-buttons {
  padding: 0 30px 30px;
}
.action-buttons > button:not(:first-child) {
  margin-top: 20px;
}
.primary-button,
.plain-button {
  height: 70px;
  line-height: 70px;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  font-weight: bold;
  font-size: 27px;
  display: block;
  width: 100%;
}

.primary-button {
  background: #3daadf;
  color: #fff;
}
.primary-button:active {
  background: #1781b5;
}

.plain-button {
  background: #f5f5f5;
  border: 2px solid #6DBAEE;
  box-sizing: border-box;
  color: #6DBAEE;
}
.plain-button:active {
  color: #6DBAEE;
  border: 2px solid #6DBAEE;
}
</style>
