<template>
  <div class="container">

    <form @submit.prevent="handleSubmit" class="form">
      <div class="input-group">
        <label for="email">Email</label>
        <input
            type="email"
            id="email"
            v-model="email"
            placeholder="Enter your email"
            required
        />
        <div class="small">You’ll need it when you log in and in case you’d like to reset your password.</div>
      </div>
      <div class="input-group">
        <label for="code">Enter the verification code received by your email</label>
        <div class="verification-code">
          <input
              type="text"
              id="code"
              v-model="code"
              placeholder="Enter code"
              required
          />
          <button type="button"  :disabled="isSending" @click="sendCode">  {{ buttonText }}</button>
        </div>
      </div>
      <button type="submit" class="next-button">Next</button>
    </form>
  </div>
</template>

<script>
import {ref, computed, onBeforeUnmount} from 'vue';
import request from "@/utils/request";
import {useRouter} from 'vue-router';
export default {
  name: 'ForgotPassword',
  components: {
  },
  setup() {
    const email = ref('');
    const code = ref('');
    const countdown = ref(60);
    const isSending = ref(false);
    const router = useRouter();
    let timer = null;

    const buttonText = computed(() => (isSending.value ? `${countdown.value}s` : 'Send'));
    onBeforeUnmount(() => {
      if (timer) {
        clearInterval(timer);
      }
    });

    const sendCode = async () => {
      if (!email.value) {
        alert('Please enter your email');
        return;
      }
      isSending.value = true;
      try {
        const response = await request({
          url: "/api/sendmail",
          method: "post",
          data: {email: email.value},
        });
        if (response) {
          // 开始倒计时
          startCountdown();
        }
      } catch (error) {
        console.error('Error sending code:', error);
        isSending.value = false;
      }
    };
    const startCountdown = () => {
      timer = setInterval(() => {
        if (countdown.value > 0) {
          countdown.value--;
        } else {
          isSending.value = false;
          countdown.value = 60;
          clearInterval(timer);
        }
      }, 1000);
    };

    const handleSubmit = async () => {

      if (!email.value) {
        alert('Please enter your email');
        return;
      }
      if (!code.value) {
        alert('Please enter the verifcation code');
        return;
      }
      //
      try {
        const response = await request({
          url: "/api/check/mail",
          method: "post",
          data: {
            email: email.value,
            code: code.value
          },
        });
        console.log(response)
        if (response) {
          router.push({ path: '/updatePwdPage', query: { code: code.value,email:email.value } });
        }
      } catch (error) {
        console.error('Error sending code:', error);
        isSending.value = false;
      }
    };

    return {
      email,
      code,
      isSending,
      countdown,
      buttonText,
      sendCode,
      handleSubmit,
    };
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: #666666;
  width: 100%;
  height: 100vh;
  padding: 30px;
  box-sizing: border-box;
}

.form {

  border-radius: 10px;
  margin-top: 100px;

}

.input-group {
  margin-bottom: 20px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 20px;
  font-weight: bold;
  font-family: Open Sans, Open Sans;
  font-weight: 400;
  font-size: 30px;
  color: #000000;
  text-align: left;
  font-style: normal;
  text-transform: none;
  line-height: 32px;
}

.input-group input {
  width: 100%;
  height: 78px;
  padding: 10px 20px;
  border: 1px solid #F1F1F1;
  border-radius: 15px;
  background-color: #F1F1F1;
  margin-bottom: 5px;
  box-sizing: border-box;
  font-size: 24px;
 color: #000000;
}

.small {
  font-size: 24px;
  color: #aaa;
  line-height: 28px;
  margin-top: 5px;
  margin-bottom: 40px;
}

.verification-code {
  display: flex;
  height: 78px;
  align-items: center;
  border: 1px solid #F1F1F1;
  border-radius: 15px;
  background-color: #F1F1F1;
}

.verification-code input {
  flex: 1;
  border: 0px solid #666666;
  border-radius:10px;
  background-color: transparent;
  color: #000000;
  box-sizing: border-box;
  font-size: 24px;
  margin: 5px;
}

.verification-code button {
  padding: 10px 15px;
  width: 124px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 28px;
  margin-right: 30px;
  background: #1781B5;
  border-radius: 90px 90px 90px 90px;
}

.verification-code button:hover {
  background-color: #007e33;
}

.next-button {
  width: 100%;
  height: 80px;
  border: none;
  background-color: #1781B5;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 30px;

  font-size: 40px;
}

.next-button:hover {
  background-color: #007e33;
}

/*@media (max-width: 480px) {
  .form {
    padding: 10px;
  }

  .verification-code button {
    padding: 8px 10px;
  }

  .next-button {
    padding: 10px;
    font-size: 40px;
  }
}*/
</style>
