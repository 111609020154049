<template>
  <div class="container">
    <MyHeader :isAvater="false"/>
    <div class="content">
      <router-link to="/serviceText" class="list-item custom-link">
        <span>Terms of Service</span>
        <span class="arrow">></span>
      </router-link>
      <router-link to="/privacyText" class="list-item custom-link">
        <span>Privacy Policy</span>
        <span class="arrow">></span>
      </router-link>
    </div>
    <div class="button-container">
      <!--      <button class="logout-button" @click="logout">Log out</button>-->
      <button class="logout-button" @click="logout">Log out</button>
<!--      <button  @click="logout" class="primary-button">Log out
      </button>-->
    </div>
  </div>
</template>

<script>
import MyHeader from "@/components/AccountHeader";

export default {
  name: 'SettingsPage',
  components: {MyHeader},
  methods: {
    openLink(page) {
      // Handle navigation to different pages
      console.log(`Navigate to ${page}`);
    },
    logout() {
      // Handle logout functionality
      // console.log('Logging out');
      // 跳转到首页并刷新页面

      localStorage.removeItem("token");
      this.$router.push('/').then(() => {
        window.location.reload();
      });
    }
  }
};
</script>

<style scoped>
body, html, .container {
  height: 100vh;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0 25px;
  box-sizing: border-box;
}

.content {
  flex-grow: 1;
}

.list-item {
  font-size: 25px;
  padding: 25px;
  margin: 30px 0;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  box-sizing: border-box;
}

.arrow {
  color: #ccc;
}

.button-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.logout-button {
  padding: 25px;
  margin-bottom: 50px;
  background-color: transparent;
  border: 3px solid #d5a4f2;
  border-radius: 12px;
  color: #d5a4f2;
  text-align: center;
  cursor: pointer;
  font-size: 30px;
  box-sizing: border-box;
  flex: 1;
  font-weight: bold;
}

.logout-button:hover {
  background-color: #d5a4f2;
  color: #fff;

}

.custom-link {
  color: inherit; /* 继承父元素颜色 */
  color: #0e0d0d; /* 自定义字体颜色 */
}


</style>
